import { render, staticRenderFns } from "./CurrentBills.vue?vue&type=template&id=846c07a8&scoped=true&"
import script from "./CurrentBills.vue?vue&type=script&lang=js&"
export * from "./CurrentBills.vue?vue&type=script&lang=js&"
import style0 from "./CurrentBills.vue?vue&type=style&index=0&id=846c07a8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "846c07a8",
  null
  
)

export default component.exports