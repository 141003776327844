<template>
  <div class="row">
    <CurrentBillsList :items="bills" :dashboard-reference="reference">
      <template #title>Recent Charging Statements</template>
    </CurrentBillsList>

    <CurrentBillsList
      :items="credits"
      :dashboard-reference="reference"
      is-credit
    >
      <template #title>Credited Charging Statements</template>
    </CurrentBillsList>

    <CurrentBillsList :items="chases" :dashboard-reference="reference" is-chase>
      <template #title>Chasing Activity</template>
    </CurrentBillsList>
  </div>
</template>

<script>
import CurrentBillsList from "@/views/Billing/Components/CurrentBillsList";

export default {
  name: "CurrentBills",
  props: {
    reference: {
      type: String,
      required: true
    },
    bills: {
      type: Array,
      required: true
    },
    credits: {
      type: Array,
      required: true
    },
    chases: {
      type: Array,
      required: true
    }
  },
  components: {
    CurrentBillsList
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/dashboard.scss";

.download-btn {
  font-size: 0.7rem;
  line-height: 1.3;
}
</style>
